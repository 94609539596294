import React from 'react';

/**
 * Footer component displays the client and server version numbers.
 *
 * @param {Object} props - The props of the component.
 * @param {string} props.clientVersion - The version number of the client.
 * @param {string} props.serverVersion - The version number of the server.
 * @returns {React.ReactElement} The Footer component.
 */
const Footer = ({ clientVersion, serverVersion }) => (
  <div id='footer' style={{ display: 'flex', justifyContent: 'right', padding: '10px' }}>
    <span>
      Client version: {clientVersion}, Server version: {serverVersion}
    </span>
  </div>
);

export default Footer;
