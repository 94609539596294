/**
 * Groups the elements of an array based on a specified field.
 *
 * This method extends the Array prototype to allow grouping of its elements by a specific field.
 * It creates an array of groups, where each group is an object containing the field value and an
 * array of elements that have that field value.
 *
 * @param {string} field - The field name to group the array elements by.
 * @returns {Array<Object>} An array of grouped objects.
 */
Array.prototype.groupBy = function (field) {
  let groupedArr = [];
  this.forEach(function (e) {
    // Look for an existent group
    let group = groupedArr.find((g) => g[field] === e[field]);
    if (group === undefined) {
      // Add new group if it doesn't exist
      group = { [field]: e[field], groupList: [] };
      groupedArr.push(group);
    }

    // Add the element to the group
    group.groupList.push(e);
  });

  return groupedArr;
};
