/**
 * A list of districts.
 *
 * This array contains the names of various police districts.
 */
const districts = [
  'Oslo',
  'Øst',
  'Innlandet',
  'Sør-Øst',
  'Agder',
  'Sør-Vest',
  'Vest',
  'Møre og Romsdal',
  'Trøndelag',
  'Nordland',
  'Troms',
  'Finnmark',
  'Kriminalomsorgen',
];

/**
 * Detailed information about each district.
 *
 * This object provides detailed information for each district,
 * including its name, unique identifier (id), postal address,
 * and the number of units.
 */
export const districtsDetailed = {
  'Oslo': {
    name: 'Oslo',
    id: 201,
    address: ['Postboks 8101 Dep', '0032 Oslo'],
    units: 387 + 30,
  },
  'Øst': {
    name: 'Øst',
    id: 202,
    address: ['Postboks 3390', '1402 Ski'],
    units: 54 + 61 + 114,
  },
  'Innlandet': {
    name: 'Innlandet',
    id: 203,
    address: ['Postboks 355', '2303 Hamar'],
    units: 62 + 21 + 43,
  },
  'Sør-Øst': {
    name: 'Sør-Øst',
    id: 204,
    address: ['Postboks 2073', '3103 Tønsberg'],
    units: 78 + 20 + 80 + 15,
  },
  'Agder': {
    name: 'Agder',
    id: 205,
    address: ['Postboks 514 Lundsiden', '4605 Kristiansand'],
    units: 33,
  },
  'Sør-Vest': {
    name: 'Sør-Vest',
    id: 206,
    address: ['Postboks 240', '4001 Stavanger'],
    units: 62 + 21,
  },
  'Vest': {
    name: 'Vest',
    id: 207,
    address: ['Postboks 285 Sentrum', '5804 Bergen'],
    units: 215 + 11,
  },
  'Møre og Romsdal': {
    name: 'Møre og Romsdal',
    id: 208,
    address: ['Postboks 1353 Sentrum', '6001 Ålesund'],
    units: 50 + 32,
  },
  'Trøndelag': {
    name: 'Trøndelag',
    id: 209,
    address: ['Postboks 2475, Sluppen', '7005 Trondheim'],
    units: 74 + 35,
  },
  'Nordland': {
    name: 'Nordland',
    id: 210,
    address: ['Postboks 1023', '8001 Bodø'],
    // FIXME: Some of the 12 units belong to 'Troms' due to district splitting
    units: 24 + 19 + 12,
  },
  'Troms': {
    name: 'Troms',
    id: 211,
    address: ['Postboks 6132', '9291 Tromsø'],
    units: 24,
  },
  'Finnmark': {
    name: 'Finnmark',
    id: 212,
    address: ['9917 Kirkenes'],
    units: 11 + 11,
  },
};

export default districts;
