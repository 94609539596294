import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { FormContextProvider } from './context/FormContext';
import { LoadingContextProvider } from './context/LoadingContext';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LoadingContextProvider>
      <AuthContextProvider>
        <FormContextProvider>
          <App />
        </FormContextProvider>
      </AuthContextProvider>
    </LoadingContextProvider>
  </React.StrictMode>,
);
