import React, { useContext } from 'react';
import { getAllAvailableProducts } from '../../common/ova_constants';
import OvaProductWithQuantity from '../../components/OvaProductWithQuantity';
import FormContext from '../../context/FormContext';
import '../../common/groupBy';
import { Container, Stack, Divider, List, ListSubheader } from '@mui/material';

const wrapperStyle = {
  display: 'flex',
  justifySelf: 'center',
  justifyContent: 'center',
};

const headerStyle = {
  fontSize: '1.5em',
  textAlign: 'start',
};

const availableProductsWrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  maxWidth: '50%',
  paddingTop: '1em',
  flexBasis: '460px',
  margin: 0,
};

const selectedProductsWrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  maxWidth: '50%',
  paddingTop: '1em',
  flexBasis: '460px',
  margin: 0,
  marginLeft: '10px',
};

/**
 * NewOrderProductForm component displays a selection of available products and a list of selected products for a new order.
 * It allows users to add available products to their order and view the products currently in their shopping cart.
 * The component utilizes FormContext to access and update the selected products in the form.
 *
 * @returns {React.ReactElement} The NewOrderProductForm component.
 */
const NewOrderProductForm = () => {
  const { form } = useContext(FormContext);
  const selectedProducts = form.products;
  const availableProducts = getAllAvailableProducts().filter(
    (product) => !selectedProducts.some((p) => p.id === product.id),
  );

  return (
    <Container sx={wrapperStyle}>
      <Container sx={availableProductsWrapperStyle}>
        <List subheader={<ListSubheader sx={headerStyle}>Tilgjengelige produkter</ListSubheader>}>
          {availableProducts.groupBy('type').map((productGroup) => (
            <React.Fragment key={productGroup.groupList}>
              {productGroup.groupList.map((product) => (
                <OvaProductWithQuantity
                  id={product.id}
                  name={product.name}
                  description={product.description}
                  initialCount={product.count}
                  addOnClick
                  key={product.name}
                />
              ))}
            </React.Fragment>
          ))}
        </List>
      </Container>
      <Divider orientation='vertical' flexItem />
      <Container sx={selectedProductsWrapperStyle}>
        <List subheader={<ListSubheader sx={headerStyle}>Handlevogn</ListSubheader>}>
          {selectedProducts.map((product, i) => (
            <OvaProductWithQuantity
              id={product.id}
              name={product.name}
              description={product.description}
              initialCount={product.count}
              key={product.id}
            />
          ))}
        </List>
      </Container>
    </Container>
  );
};

export default NewOrderProductForm;
