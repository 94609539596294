import API from './api';
import { generateSessionToken } from './addressSearch';

/**
 * Maps a status code to a human-readable error message.
 *
 * This function takes an HTTP status code and returns an appropriate
 * error message. It covers specific cases like unauthorized
 * access or server errors, and provides a generic message for other cases.
 *
 * @param {number} statusCode - The HTTP status code to translate.
 * @returns {string} A string representing the user-friendly error message.
 */
export const statusCodeToErrorMessage = (statusCode) => {
  switch (statusCode) {
    case 401:
      return 'Feil brukernavn eller passord';
    case 500:
    case 404:
      return 'Kunne ikke koble til server';
    default:
      return 'Ukjent problem ved innlogging';
  }
};

/**
 * Verifies a token by making an API POST request.
 *
 * This function sends a token to the API's 'login/verifyToken' endpoint
 * to verify its validity. It returns the response from the API.
 *
 * @param {string} token - The token to be verified.
 * @returns {Promise<Object>} A promise that resolves to the response from the API.
 */
export const verifyToken = async (token) => {
  const res = await API.POST('login/verifyToken', token);
  return res;
};

/**
 * Logs in a user using their credentials.
 *
 * This is the default exported asynchronous function of the module.
 * It sends the user's credentials to the API's 'login/' endpoint
 * and then calls `generateSessionToken()` to create a session token.
 * It returns the token provided by the login response.
 *
 * @param {Object} credentials - The user's login credentials.
 * @returns {Promise<string>} A promise that resolves to the user's token.
 */
export default async (credentials) => {
  const res = await API.POST('login/', credentials);
  await generateSessionToken();
  return res.token;
};
