import React, { createContext, useState } from 'react';
import loginRequest, { statusCodeToErrorMessage, verifyToken } from '../api/login';

/**
 * AuthContext is a React context for managing authentication-related data.
 * It holds information about the user's authentication state, including the login status, token, and error messages.
 */
const AuthContext = createContext();

/**
 * AuthContextProvider is a provider component for AuthContext.
 * It wraps its children components and provides them with authentication-related data and functions.
 *
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.children - The child components that will consume the context.
 * @returns {React.ReactElement} The AuthContextProvider component.
 */
export const AuthContextProvider = ({ children }) => {
  const token = localStorage.getItem('token') || null;

  const login = async (credentials) => {
    try {
      const newToken = await loginRequest(credentials);
      setState({ ...auth, email: credentials.username, isLoggedIn: true, token: newToken });
      localStorage.setItem('token', newToken);
    } catch (err) {
      const errorMessage = statusCodeToErrorMessage(err.status);
      setState({ ...auth, loginErrorMessage: errorMessage });
    }
  };

  const tokenLogin = async (token) => {
    try {
      const { info } = await verifyToken({ token: token });
      setState({ ...auth, email: info.email, isLoggedIn: true });
    } catch (e) {
      localStorage.removeItem('token');
      setState(initialState);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setState({
      ...auth,
      isLoggedIn: false,
      loginErrorMessage: null,
      token: null,
    });
  };

  const clearErrorMessage = () => setState({ ...auth, loginErrorMessage: '' });

  const initialState = {
    email: '',
    isLoggedIn: false,
    loginErrorMessage: null,
    token,
    login,
    tokenLogin,
    logout,
    clearErrorMessage,
  };

  const [auth, setState] = useState(initialState);

  return <AuthContext.Provider value={{ auth, setState }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
