import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays, isWeekend, add } from 'date-fns';
import nb from 'date-fns/locale/nb';
import { TextField } from '@mui/material';

// Function to add business days, excluding weekends
const addBusinessDays = (date, days) => {
  let result = new Date(date);
  let addedDays = 0;
  while (addedDays < days) {
    result = addDays(result, 1);
    if (!isWeekend(result)) {
      addedDays += 1;
    }
  }
  return result;
};

// Calculate default date
const defaultDate = addBusinessDays(new Date(), 3);
const minDate = add(new Date(), { days: 1 });

/**
 * A Material-UI based DatePicker component localized for Norwegian language.
 * It provides a user-friendly way to pick dates, ensuring the selected date is not earlier than tomorrow.
 *
 * @param {Object} props - The props to pass to the DatePicker.
 * @param {string} props.name - The name of the DatePicker field, used in form structures.
 * @param {Date} props.value - The currently selected date value.
 * @param {Function} props.onChange - Callback function to handle the change event of the date picker.
 * @param {boolean} [props.error] - Optional prop to indicate if the field should display an error state.
 * @returns {React.ReactElement} A localized DatePicker component.
 */
const Datepicker = (props) => {
  const onChange = (newValue) => {
    props.onChange({
      target: {
        name: props.name,
        value: newValue,
      },
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nb}>
      <MuiDatePicker
        {...props}
        value={props.value || defaultDate}
        minDate={minDate}
        onChange={onChange}
        timezone='Europe/Oslo'
        textField={(params) => <TextField {...params} {...props} fullWidth color='primary' error={props.error} />}
      />
    </LocalizationProvider>
  );
};

export default Datepicker;
