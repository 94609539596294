import React, { useState, useContext, useEffect } from 'react';
import Datepicker from '../../components/Datepicker';
import FormContext from '../../context/FormContext';
import {
  Box,
  Paper,
  Stack,
  TextField,
  Button,
  Radio,
  FormControlLabel,
  RadioGroup,
  Alert,
  Typography,
} from '@mui/material';
import NewAddressForm from '../../components/NewAddressForm';
import { add, isBefore, startOfDay, isWeekend, addDays } from 'date-fns';

const wrapperStyle = {
  maxWidth: '400px',
  margin: '0 auto',
};

// Function to add business days, excluding weekends
const addBusinessDays = (date, days) => {
  let result = new Date(date);
  let addedDays = 0;
  while (addedDays < days) {
    result = addDays(result, 1);
    if (!isWeekend(result)) {
      addedDays += 1;
    }
  }
  return result;
};

/**
 * NewOrderDeliveryForm component provides a form for entering delivery details for a new order.
 * It includes inputs for the delivery date, address, and an optional comment section.
 * The component uses FormContext to manage the state and validation of form fields.
 *
 * @returns {React.ReactElement} The NewOrderDeliveryForm component.
 */
export default function NewOrderDeliveryForm() {
  const { form, dispatch } = useContext(FormContext);
  const [errorMessage, setErrorMessage] = useState(false);
  const [deliveryOption, setDeliveryOption] = useState('standard');
  const [showCommentHelperText, setShowCommentHelperText] = useState(false);

  useEffect(() => {
    const defaultDate = addBusinessDays(new Date(), 3);
    if (!form.deliveryDate) {
      dispatch({ deliveryDate: defaultDate });
    }

    const formDateStart = startOfDay(new Date(form.deliveryDate));
    const defaultDateStart = startOfDay(defaultDate);

    if (deliveryOption === 'standard' && isBefore(formDateStart, defaultDateStart)) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  }, [form.deliveryDate, dispatch, deliveryOption]);

  const addressIsValid =
    form &&
    form.name &&
    form.phone &&
    ((form.useManualPickup && form.manualPickupDistrict) ||
      (form.address &&
        form.address.name &&
        form.address.address &&
        form.address.postalCode &&
        form.address.postalTown));

  const handleRadioChange = (event) => {
    const option = event.target.value;
    setDeliveryOption(option);

    const standardDeliveryDate = addBusinessDays(new Date(), 3);
    const ekspressDeliveryDate = add(new Date(), { days: 1 });

    if (option === 'standard') {
      dispatch({ type: 'setDeliveryDate', deliveryDate: standardDeliveryDate });
    }

    if (option === 'ekspress') {
      dispatch({ type: 'setDeliveryDate', deliveryDate: ekspressDeliveryDate });
    }

    dispatch({ type: 'setIsUrgent', isUrgent: option === 'ekspress' });
  };

  const onChangeDelivery = (ev) => {
    dispatch({ [ev.target.name]: ev.target.value });
  };

  const onChange = (ev) => {
    if (ev.target.name === 'addressName') {
      const newAddress = { ...form.address, name: ev.target.value };
      dispatch({ address: newAddress });
      return;
    }
    dispatch({ [ev.target.name]: ev.target.value });
  };

  return (
    <Box style={wrapperStyle}>
      <Stack spacing={3}>
        <Datepicker
          label='Ønsket leveringsdato'
          value={form.deliveryDate}
          name='deliveryDate'
          onChange={onChangeDelivery}
          required
          error={!!errorMessage}
          helperText={errorMessage || 'Velg en dato'}
        />
        {errorMessage && (
          <Alert severity='error'>Kan ikke velge mindre enn 3 dagers leveringstid på standard bestilling</Alert>
        )}
        <Box>
          <RadioGroup defaultValue='standard'>
            <FormControlLabel
              value='standard'
              control={<Radio checked={deliveryOption === 'standard'} onChange={handleRadioChange} />}
              label={
                <Box>
                  <Typography variant='subtitle1' component='span'>
                    Standard leveranse
                  </Typography>
                </Box>
              }
            />
            <Typography
              variant='body2'
              color='textSecondary'
              sx={{ alignItems: 'flex-start', textAlign: 'left', width: '100%' }}>
              Leveringstid innen 3 virkedager
            </Typography>
            <FormControlLabel
              value='ekspress'
              control={<Radio checked={deliveryOption === 'ekspress'} onChange={handleRadioChange} />}
              label={
                <Box>
                  <Typography variant='subtitle1' component='span'>
                    Ekspress
                  </Typography>
                </Box>
              }
            />
          </RadioGroup>
          <Typography
            variant='body2'
            color='textSecondary'
            sx={{ alignItems: 'flex-start', textAlign: 'left', width: '100%' }}>
            Leveres før kl 09.00 neste virkedag (ved bestilling før kl 14:00)
          </Typography>
        </Box>
        <NewAddressForm onChange={onChange} />
        <TextField
          multiline
          minRows={3}
          name='comment'
          label='Kommentar (valgfritt)'
          value={form.comment}
          onChange={onChange}
          helperText={showCommentHelperText && 'NB! ikke legg inn sensitiv informasjon som f.eks. navn på domfelte'}
          onFocus={() => setShowCommentHelperText(true)}
          onBlur={() => setShowCommentHelperText(false)}
        />
      </Stack>
    </Box>
  );
}
