import React, { useContext, useState } from 'react';
import Delete from '@mui/icons-material/DeleteForever';
import styled from 'styled-components';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import FormContext from '../context/FormContext';
import { Button, IconButton, ListItem, ListItemText } from '@mui/material';

const NumberButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  background: ${(props) => (props.disabled ? '#f0f0f0' : '#1976d2')};
  color: ${(props) => (props.disabled ? '#aaa' : '#fff')};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    ${(props) =>
      !props.disabled &&
      `
      cursor: pointer;
      background: #1565c0;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    `}
  }
`;

const NumberInput = styled.input`
  width: 40px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 1em;
  color: #222;
  background: #fff;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border-color: #1976d2;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 3px #1976d2;
  }
`;

const ButtonInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
`;

const RightAlignContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
`;

const iconWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

/**
 * OvaProductWithQuantity component displays a product with the ability to adjust its quantity and add or remove it from a form context.
 * It provides buttons for increasing, decreasing, or setting a specific quantity, as well as adding the product to a form.
 *
 * @param {Object} props - The props of the component.
 * @param {string} props.id - The unique identifier of the product.
 * @param {string} props.name - The name of the product.
 * @param {string} props.description - A description of the product.
 * @param {number} props.initialCount - The initial quantity count of the product.
 * @param {boolean} props.addOnClick - Indicates whether the add button should be displayed.
 * @returns {React.ReactElement} The OvaProductWithQuantity component.
 */
const OvaProductWithQuantity = ({ id, name, description, initialCount, addOnClick }) => {
  const [count, setCount] = useState(initialCount);
  const { addProduct, removeProduct, setProductCount } = useContext(FormContext);

  const updateCount = (newCount) => {
    setCount(newCount);
    setProductCount(name, newCount);
  };

  return (
    <ListItem key={id} divider={true} alignItems='center'>
      <ListItemText primary={name} secondary={description} />
      <RightAlignContainer>
        {addOnClick ? (
          <Button
            variant='outlined'
            style={{ minWidth: '9em' }}
            onClick={() => addProduct(id, name, count, description)}
            endIcon={<AddShoppingCartIcon />}>
            Legg Til
          </Button>
        ) : (
          <>
            <ButtonInputContainer>
              <NumberButton disabled={count <= 1} onClick={() => updateCount(count - 1)}>
                -
              </NumberButton>
              <NumberInput anySelected={count > 1} type='text' value={count || 0} disabled></NumberInput>
              <NumberButton onClick={() => updateCount(count + 1)}>+</NumberButton>
            </ButtonInputContainer>
            <div style={iconWrapperStyle}>
              <IconButton variant='outlined' size='large' onClick={() => removeProduct(name)}>
                <Delete fontSize='large' />
              </IconButton>
            </div>
          </>
        )}
      </RightAlignContainer>
    </ListItem>
  );
};

export default OvaProductWithQuantity;
