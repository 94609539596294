import { useState, useEffect } from 'react';

/**
 * useDebounce is a custom hook that returns a debounced value.
 * It delays updating the value until after a specified wait time has elapsed since the last time the value was updated.
 * This is useful for performing some action only after the user has stopped typing for a certain period of time (like delaying a search query).
 *
 * @param {any} value - The value to be debounced.
 * @param {number} delay - The debounce delay in milliseconds.
 * @returns {any} The current debounced value.
 */
export default function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
