import React, { useContext } from 'react';
import { Button, TextField, Box, Stack, Divider } from '@mui/material';
import FormContext from '../../context/FormContext';
import AuthContext from '../../context/AuthContext';
import formatAddress from '../../common/formatAddress';
import EmailRecipients from '../../components/EmailRecipients';

/**
 * Formats a JavaScript Date object into a human-readable string in Norwegian locale.
 * It formats the date to display the weekday, year, month, and day in a long format with the first letter capitalized.
 *
 * @param {Date} date - The Date object to be formatted.
 * @returns {string} A string representing the formatted date.
 */
const formatDate = (date) => {
  const string = date.toLocaleString('no-NB', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * NewOrderConfirmation component displays a summary of the new order details.
 * It allows editing of products, delivery information, and comments.
 * This component also handles adding and removing CC email addresses for order confirmation.
 *
 * @param {Object} props - The props of the component.
 * @param {Function} props.onEditProducts - Function to handle the editing of products.
 * @param {Function} props.onEditDelivery - Function to handle the editing of delivery details.
 * @param {Function} props.onEditComment - Function to handle the editing of the order comment.
 * @returns {React.ReactElement} The NewOrderConfirmation component.
 */
const NewOrderConfirmation = ({ onEditProducts, onEditDelivery, onEditComment }) => {
  const { form, dispatch } = useContext(FormContext);
  const { auth } = useContext(AuthContext);

  const onAddCC = (email) => {
    dispatch({
      extraEmailRecipients: [...form.extraEmailRecipients, email],
    });
  };

  const onRemoveCCIndex = (index) => {
    const newRecipients = form.extraEmailRecipients.filter((_, i) => i !== index);
    dispatch({
      extraEmailRecipients: newRecipients,
    });
  };

  return (
    <Stack
      sx={{ width: '500px', margin: '0 auto 24px' }}
      alignItems='stretch'
      divider={<Divider orientation='horizontal' flexItem />}
      spacing={2}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack sx={{ width: '100px' }} justifyContent='center'>
          <p>Produkter</p>
          <Button variant='outlined' onClick={onEditProducts}>
            Endre
          </Button>
        </Stack>
        <Stack sx={{ width: '250px', textAlign: 'start' }}>
          {form.products.map((product) => (
            <p key={product.name}>
              {product.count}x {product.name}
            </p>
          ))}
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack sx={{ width: '100px' }} justifyContent='center'>
          <p>Levering</p>
          <Button variant='outlined' onClick={onEditDelivery}>
            Endre
          </Button>
        </Stack>
        <Stack sx={{ width: '250px', textAlign: 'start' }}>
          <p>{formatDate(form.deliveryDate)}</p>
          <TextField multiline disabled value={formatAddress(form)} />
        </Stack>
      </Box>
      {form.comment && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Stack sx={{ width: '100px' }} justifyContent='center'>
            <p>Kommentar</p>
            <Button onClick={onEditComment}>Endre</Button>
          </Stack>
          <Stack sx={{ width: '250px', textAlign: 'start' }}>
            <TextField multiline minRows={3} disabled value={form.comment} />
          </Stack>
        </Box>
      )}
      <Stack>
        <p>Bestillingsbekreftelse sendes til</p>
        <EmailRecipients
          to={auth.email}
          cc={form.extraEmailRecipients}
          onAddCC={onAddCC}
          onRemoveCCIndex={onRemoveCCIndex}
        />
        <p>NB! Hvis du ønsker bestillingsbekreftelse til din personlige e-post, så må den legges til her. </p>
      </Stack>
    </Stack>
  );
};

export default NewOrderConfirmation;
