import React from 'react';
import { createContext, useState } from 'react';

/**
 * LoadingContext is a React context for managing loading states across the application.
 * It provides a way to set and access a loading indicator, typically used during data fetching or other asynchronous operations.
 */
const LoadingContext = createContext();

/**
 * LoadingContextProvider is a provider component for LoadingContext.
 * It wraps its children components and provides them with access to the isLoading state and a function to update this state.
 *
 * @param {Object} props - The props of the component.
 * @param {React.ReactNode} props.children - The child components that will consume the context.
 * @returns {React.ReactElement} The LoadingContextProvider component.
 */
export const LoadingContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return <LoadingContext.Provider value={{ isLoading, setIsLoading }}>{children}</LoadingContext.Provider>;
};

export default LoadingContext;
