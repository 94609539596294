import React, { useState, useContext, useEffect } from 'react';
import AddressAutoComplete from './AddressAutoComplete';
import FormContext from '../context/FormContext';
import { Checkbox, FormControlLabel, TextField, Button } from '@mui/material';
import DistrictSelect from './DistrictSelect';
import manualPickupAddress, { addressMatchManualPickup } from '../common/manualPickupAddress';
import { validateAddressName, validateName, validatePhoneNumber } from '../common/validators';

/**
 * NewAddressForm component provides a form for entering a new address.
 * It includes fields for contact person, mobile number, and address details.
 * Additionally, it offers an option for manual pickup with custom address logic.
 *
 * @param {Object} props - The props of the component.
 * @param {Function} props.onChange - Function to handle changes in the form fields.
 * @returns {React.ReactElement} The NewAddressForm component.
 */
const NewAddressForm = ({ onChange }) => {
  const { form, dispatch } = useContext(FormContext);
  const [showAddressNameHelperText, setShowAddressNameHelperText] = useState(false);

  useEffect(() => {
    // if the user's last order was a manual pickup, check manual delivery checkbox
    if (addressMatchManualPickup(form.address)) {
      dispatch({ useManualPickup: true });
    }
  }, []);

  const onChangeManualPickup = (ev) => {
    dispatch({ useManualPickup: ev.target.checked });
  };

  const clean = () => {
    dispatch({
      name: '',
      phone: '',
      address: {
        address: '',
        name: '',
        postalTown: '',
        postalCode: '',
      },
      district: '',
    });
  };

  return (
    <>
      <Button onClick={clean} variant='outlined'>
        Tøm felter
      </Button>
      <TextField
        label='Kontaktperson'
        name='name'
        value={form.name}
        placeholder='Fullt navn på mottaker'
        onChange={onChange}
        required
        error={!validateName(form.name)}
        helperText={
          form.name !== '' && !validateName(form.name) && 'Tillatte tegn: Bokstaver, mellomrom og spesialtegnene , . -'
        }
      />
      <TextField
        label='Mobilnummer'
        name='phone'
        value={form.phone}
        placeholder='Mobilnummer (8 siffer)'
        onChange={onChange}
        required
        error={!validatePhoneNumber(form.phone)}
        helperText={!validatePhoneNumber(form.phone) && '8 siffer (uten landskode)'}
      />
      <FormControlLabel
        control={<Checkbox checked={form.useManualPickup} onChange={onChangeManualPickup} />}
        label='Hentes hos Politiets IT-enhet Majorstuen'
      />
      {form.useManualPickup && (
        <DistrictSelect name='manualPickupDistrict' value={form.manualPickupDistrict} onChange={onChange} />
      )}
      {!form.useManualPickup && <DistrictSelect name='district' value={form.district} onChange={onChange} />}
      <TextField
        label='Mottakersted'
        name='addressName'
        value={(form.useManualPickup && manualPickupAddress.name) || form.address.name}
        placeholder='Mottakersted'
        onChange={onChange}
        helperText={showAddressNameHelperText && 'Navn på politistasjon, fengsel eller lignende'}
        onFocus={() => setShowAddressNameHelperText(true)}
        onBlur={() => setShowAddressNameHelperText(false)}
        disabled={form.useManualPickup}
        required
        error={!form.useManualPickup && !validateAddressName(form.address.name)}
      />
      <AddressAutoComplete
        name='address'
        value={form.address}
        placeholder='Leveringsadresse'
        onChange={onChange}
        forcedAddress={form.useManualPickup && manualPickupAddress}
      />
    </>
  );
};

export default NewAddressForm;
