/**
 * An object representing the address for manual pickup.
 *
 * This constant defines a specific address used for manual pickups,
 * including the name, street address, postal code, and town.
 */
const manualPickupAddress = {
  name: 'Politiets IT-enhet',
  address: 'Fridtjof Nansens vei 14',
  postalCode: '0369',
  postalTown: 'Oslo',
};

/**
 * Checks if a given address matches the manual pickup address.
 *
 * This function compares each field of the input address with the
 * manual pickup address. It returns true if all fields match, indicating
 * that the given address is the same as the manual pickup address.
 *
 * @param {Object} address - The address to be compared.
 * @returns {boolean} True if the address matches the manual pickup address, false otherwise.
 */
export const addressMatchManualPickup = (address) => {
  if (address.name !== manualPickupAddress.name) return false;
  if (address.address !== manualPickupAddress.address) return false;
  if (address.postalCode !== manualPickupAddress.postalCode) return false;
  if (address.postalTown !== manualPickupAddress.postalTown) return false;
  return true;
};

export default manualPickupAddress;
