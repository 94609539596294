/**
 * Defines types of products.
 */
export const productType = {
  BRACELET: 'bracelet',
  BATTERY: 'battery',
  DOMFELT_MOBIL: 'domfeltMobil',
  OTHER: 'other',
};

/**
 * Enumerates various statuses for OVA.
 */
export const OVA_STATUS = {
  DEFAULT: 'default',
  BROKEN: 'broken',
  SERVICE: 'service',
  COMPLETE: 'complete',
  VERIFIED: 'verified',
  TO_VERIFY: 'to_verify',
  TO_VERIFY_WITH_ISSUE: 'to_verify_issue',
  ARMED: 'armed',
};

/**
 * Defines actions that can be taken on an OVA
 */
export const OVA_ACTIONS = {
  ADD_EQUIPMENT: 'add_equipment',
  REMOVE_EQUIPMENT: 'remove_equipment',
  EDIT: 'edit',
  SHIP: 'ship',
};

/**
 * Specifies the environment where the OVA system operates.
 */
export const OVA_ENV = {
  TUPP_UTV: 'tupp_utv',
  TUPP_TEST: 'tupp_test',
  TUPP_PRE: 'tupp_pre',
  PROD: 'prod',
};

/**
 * NAME: Name of the bracelet model
 * MANUFACTURER: Name of the manufacturer
 * EQUIPMENT: Equipment used by the bracelet model
 */
export const BRACELETS = {
  TESTBRACELET: {
    NAME: 'TESTBRACELET',
    MANUFACTURER: 'NONE',
    EQUIPMENT: {},
  },
  GS01: {
    NAME: 'GS01',
    MANUFACTURER: 'Geosatis',
    EQUIPMENT: {
      BATTERY_S: 'gs01_battery_small',
      BATTERY_M: 'gs01_battery_medium',
      BATTERY_L: 'gs01_battery_large',
      EXTERNAL_CHARGER: 'gs01_external_charger',
      SERVICE_CHARGER: 'gs01_service_charger',
      KEY: 'gs01_key',
    },
  },
  GMS60: {
    NAME: 'GMS60',
    MANUFACTURER: 'Geosatis',
    EQUIPMENT: {
      BATTERY_S: 'gms60_battery_small',
      BATTERY_M: 'gms60_battery_medium',
      BATTERY_L: 'gms60_battery_large',
      BATTERY_XL: 'gms60_battery_xlarge',
      EXTERNAL_CHARGER: 'gms60_external_charger',
      SERVICE_CHARGER: 'gms60_service_charger',
      KEY: 'gms60_key',
      WIRED_CHARGER: 'gms60_wired_charger',
    },
  },
  DOMFELTMOBIL: {
    NAME: 'Domfeltmobil',
    MANUFACTURER: 'NONE',
    EQUIPMENT: {},
  },
};

/**
 * The default bracelet model name.
 */
export const BRACELET_DEFAULT = BRACELETS.GS01.NAME;

/**
 * Units related to bracelet models.
 */
export const BRACELET_UNITS = {
  GMS60_SET_S: 'gms60_set_s',
  GMS60_SET_M: 'gms60_set_m',
  GMS60_SET_L: 'gms60_set_l',
  GMS60_SET_XL: 'gms60_set_xl',
};

/**
 * Additional units associated with the bracelets.
 */
export const EXTRA_UNITS = {
  GMS60_KEY: BRACELETS.GMS60.EQUIPMENT.KEY,
  GMS60_BATTERY_S: BRACELETS.GMS60.EQUIPMENT.BATTERY_S,
  GMS60_BATTERY_M: BRACELETS.GMS60.EQUIPMENT.BATTERY_M,
  GMS60_BATTERY_L: BRACELETS.GMS60.EQUIPMENT.BATTERY_L,
  GMS60_BATTERY_XL: BRACELETS.GMS60.EQUIPMENT.BATTERY_XL,
  GMS60_EXTERNAL_CHARGER: BRACELETS.GMS60.EQUIPMENT.EXTERNAL_CHARGER,
  GMS60_WIRED_CHARGER: BRACELETS.GMS60.EQUIPMENT.WIRED_CHARGER,
};

/**
 * Defines the domfeltmobil unit.
 */
export const DOMFELT_MOBIL = {
  DOMFELTMOBIL: 'Domfeltmobil',
};

/**
 * Other units that might be included in an order.
 */
export const OTHER_UNITS = {
  GMS60_EXTERNAL_CHARGER: BRACELETS.GMS60.EQUIPMENT.EXTERNAL_CHARGER,
  GMS60_WIRED_CHARGER: BRACELETS.GMS60.EQUIPMENT.WIRED_CHARGER,

  GMS60_KEY: BRACELETS.GMS60.EQUIPMENT.KEY,
};

/**
 * Accessories that can be included in an order.
 */
export const ACCESSORIES = {
  USB_CHARGER: 'usb_charger',
  MEASURING_TAPE: 'measuring_tape',
  COMFORT_ELEMENT_S: 'comfort_s',
  COMFORT_ELEMENT_M: 'comfort_m',
  COMFORT_ELEMENT_L: 'comfort_l',
  COMFORT_ELEMENT_XL: 'comfort_xl',
};

/**
 * All types of units that can be included in an order.
 */
export const ORDER_UNITS = {
  ...BRACELET_UNITS,
  ...DOMFELT_MOBIL,
  ...OTHER_UNITS,
  ...ACCESSORIES,
};

/**
 * Descriptions for all order units.
 */
const productDescriptions = {
  [ORDER_UNITS.GMS60_SET_S]: 'Inneholder 1 eksternt batteri, 1 kablet lenkelader, 2 komfortelementer samt 2 USB-ladere',
  [ORDER_UNITS.GMS60_SET_M]:
    'Inneholder 1 eksternt batteri,  1 kablet lenkelader, 2 komfortelementer samt 2 USB-ladere',
  [ORDER_UNITS.GMS60_SET_L]:
    'Inneholder 1 eksternt batteri,  1 kablet lenkelader, 2 komfortelementer samt 2 USB-ladere',
  [ORDER_UNITS.GMS60_SET_XL]:
    'Inneholder 1 eksternt batteri,  1 kablet lenkelader, 2 komfortelementer samt 2 USB-ladere',
  [BRACELETS.GMS60.EQUIPMENT.EXTERNAL_CHARGER]: 'USB-lader og kabel medfølger',
  [BRACELETS.GMS60.EQUIPMENT.WIRED_CHARGER]: 'Lader med stikkontakt',
  [BRACELETS.GMS60.EQUIPMENT.KEY]: 'USB-lader og kabel medfølger',
  [ACCESSORIES.USB_CHARGER]: 'Kabel + AC-adapter',
  [ACCESSORIES.MEASURING_TAPE]: 'Målebånd',
  [ACCESSORIES.COMFORT_ELEMENT_S]: 'Komfortelement til Small lenke',
  [ACCESSORIES.COMFORT_ELEMENT_M]: 'Komfortelement til Medium lenke',
  [ACCESSORIES.COMFORT_ELEMENT_L]: 'Komfortelement til Large lenke',
  [ACCESSORIES.COMFORT_ELEMENT_XL]: 'Komfortelement til Ekstra Large lenke',
};

/**
 * Mapping of equipment types to their readable names.
 */
const equipmentTypeMap = {
  [BRACELETS.GMS60.EQUIPMENT.EXTERNAL_CHARGER]: 'Eksternt batteri',
  [BRACELETS.GMS60.EQUIPMENT.WIRED_CHARGER]: 'Lader med kabel',
  [BRACELETS.GMS60.EQUIPMENT.SERVICE_CHARGER]: 'Servicelader',
  [BRACELETS.GMS60.EQUIPMENT.KEY]: 'KEY',
};

/**
 * Returns the readable name for a given equipment type.
 * @param {string} type - The type of the equipment.
 * @returns {string} The readable name of the equipment type.
 */
export function getReadableEquipmentType(type) {
  return equipmentTypeMap[type] || type;
}

/**
 * Mapping of order units to their readable names.
 */
const ovaOrderUnitsMap = {
  [ORDER_UNITS.GMS60_SET_S]: 'Lenkesett Small',
  [ORDER_UNITS.GMS60_SET_M]: 'Lenkesett Medium',
  [ORDER_UNITS.GMS60_SET_L]: 'Lenkesett Large',
  [ORDER_UNITS.GMS60_SET_XL]: 'Lenkesett Ekstra Large',
  [ORDER_UNITS.GMS60_KEY]: 'KEY-verktøy',
  [ORDER_UNITS.GMS60_BATTERY_S]: equipmentTypeMap[BRACELETS.GMS60.EQUIPMENT.BATTERY_S],
  [ORDER_UNITS.GMS60_BATTERY_M]: equipmentTypeMap[BRACELETS.GMS60.EQUIPMENT.BATTERY_M],
  [ORDER_UNITS.GMS60_BATTERY_L]: equipmentTypeMap[BRACELETS.GMS60.EQUIPMENT.BATTERY_L],
  [ORDER_UNITS.GMS60_BATTERY_XL]: equipmentTypeMap[BRACELETS.GMS60.EQUIPMENT.BATTERY_XL],
  [ORDER_UNITS.GMS60_EXTERNAL_CHARGER]: equipmentTypeMap[BRACELETS.GMS60.EQUIPMENT.EXTERNAL_CHARGER],
  [ORDER_UNITS.GMS60_WIRED_CHARGER]: equipmentTypeMap[BRACELETS.GMS60.EQUIPMENT.WIRED_CHARGER],
  [ORDER_UNITS.DOMFELTMOBIL]: 'Domfeltmobil',
  [ACCESSORIES.USB_CHARGER]: 'USB-Lader',
  [ACCESSORIES.MEASURING_TAPE]: 'Målebånd',
  [ACCESSORIES.COMFORT_ELEMENT_S]: 'Komfortelement til Small lenke',
  [ACCESSORIES.COMFORT_ELEMENT_M]: 'Komfortelement til Medium lenke',
  [ACCESSORIES.COMFORT_ELEMENT_L]: 'Komfortelement til Large lenke',
  [ACCESSORIES.COMFORT_ELEMENT_XL]: 'Komfortelement til Ekstra Large lenke',
};

/**
 * Returns the readable name for a given order unit.
 * @param {string} type - The type of the order unit.
 * @returns {string} The readable name of the order unit.
 */
export function getReadableOrderUnit(type) {
  return ovaOrderUnitsMap[type] || type;
}

/**
 * Retrieves a map of all order units with their readable names.
 * @returns {Object} An object with keys as unit types and values as readable names.
 */
export function getAllReadableOrderUnits() {
  const units = {};
  Object.entries(ORDER_UNITS).forEach((product) => (units[product[0]] = getReadableOrderUnit(product[1])));
  return units;
}

/**
 * Retrieves a map of all bracelet units with their readable names.
 * @returns {Object} An object with keys as bracelet unit types and values as readable names.
 */
export function getAllReadableBraceletUnits() {
  const units = {};
  Object.entries(BRACELET_UNITS).forEach((product) => (units[product[0]] = getReadableOrderUnit(product[1])));
  return units;
}

/**
 * Retrieves a map of all domfelt mobile units with their readable names.
 * @returns {Object} An object with keys as domfelt mobile unit types and values as readable names.
 */
export function getAllReadableDomfeltMobil() {
  const units = {};
  Object.entries(DOMFELT_MOBIL).forEach((product) => (units[product[0]] = getReadableOrderUnit(product[1])));
  return units;
}

/**
 * Retrieves a map of all other units with their readable names.
 * @returns {Object} An object with keys as other unit types and values as readable names.
 */
export function getAllReadableOtherUnits() {
  const units = {};
  Object.entries(OTHER_UNITS).forEach((product) => (units[product[0]] = getReadableOrderUnit(product[1])));
  return units;
}

/**
 * Retrieves a map of all accessory units with their readable names.
 * @returns {Object} An object with keys as accessory unit types and values as readable names.
 */
export function getAllReadableAccessories() {
  const units = {};
  Object.entries(ACCESSORIES).forEach((product) => (units[product[0]] = getReadableOrderUnit(product[1])));
  return units;
}

/**
 * Retrieves an array of all available products with details.
 * @returns {Array} An array of product objects with details like id, name, type, count, and description.
 */
export function getAllAvailableProducts() {
  const bracelets = Object.entries(getAllReadableBraceletUnits()).map((product) => ({
    id: ORDER_UNITS[product[0]],
    name: product[1],
    type: productType.BRACELET,
    count: 1,
    description: productDescriptions[ORDER_UNITS[product[0]]] || '',
  }));
  const domfeltMobil = Object.entries(getAllReadableDomfeltMobil()).map((product) => ({
    id: ORDER_UNITS[product[0]],
    name: product[1],
    type: productType.DOMFELT_MOBIL,
    count: 1,
    description: productDescriptions[ORDER_UNITS[product[0]]] || '',
  }));
  const other = Object.entries(getAllReadableOtherUnits()).map((product) => ({
    id: ORDER_UNITS[product[0]],
    name: product[1],
    type: productType.OTHER,
    count: 1,
    description: productDescriptions[ORDER_UNITS[product[0]]] || '',
  }));
  const accessories = Object.entries(getAllReadableAccessories()).map((product) => ({
    id: ORDER_UNITS[product[0]],
    name: product[1],
    type: productType.OTHER,
    count: 1,
    description: productDescriptions[ORDER_UNITS[product[0]]] || '',
  }));
  return [...bracelets, ...domfeltMobil, ...other, ...accessories];
}
