import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import districts from '../common/districts';

/**
 * A Material-UI based dropdown component for selecting a district.
 * It renders a list of options based on the provided districts array.
 *
 * @param {Object} props - The component props.
 * @param {string} props.name - The name attribute for the Select, used for form handling.
 * @param {string} props.value - The currently selected value in the dropdown.
 * @param {Function} props.onChange - The function to call when the value of the dropdown changes.
 * @returns {React.ReactElement} A Select dropdown component for district selection.
 */
const DistrictSelect = ({ name, value, onChange }) => (
  <FormControl fullWidth>
    <InputLabel id='district-select-label'>Distrikt</InputLabel>
    <Select
      labelId='district-select-label'
      id='district-select'
      label='Distrikt'
      name={name}
      value={value}
      onChange={onChange}>
      {districts.map((district) => (
        <MenuItem value={district} key={district}>
          {district}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default DistrictSelect;
