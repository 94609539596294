import React, { createContext, useState } from 'react';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { ORDER_UNITS } from '../common/ova_constants';
import { getUserDetails, submitOrder } from '../api/orders';
import * as R from 'ramda';
import { districtsDetailed } from '../common/districts';
import manualPickupAddress, { addressMatchManualPickup } from '../common/manualPickupAddress';

/**
 * FormContext is a React context for managing form-related data.
 * It holds information and functions relevant to form processing, such as form state and form submission.
 */
const FormContext = createContext();

const initialState = {
  deliveryDate: null,
  isUrgent: false,
  name: '',
  phone: '',
  address: {
    name: '',
    address: '',
    postalCode: '',
    postalTown: '',
  },
  useManualPickup: false,
  manualPickupDistrict: '', // field used to force district value in Eynventory if user selects manual pickup
  district: '', // New district field
  comment: '',
  products: [],
  extraEmailRecipients: process.env.APP_ENV === 'prod' ? ['postmottak.ova@kriminalomsorg.no'] : [],
};

/**
 * FormContextProvider is a provider component for FormContext.
 * It wraps its children components and provides them with form-related data and functions.
 *
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.children - The child components that will consume the context.
 * @returns {React.ReactElement} The FormContextProvider component.
 */
export const FormContextProvider = ({ children }) => {
  const [form, updateForm] = useState({ ...{ ...initialState } });

  const onChangeForm = (newField) => {
    updateForm({ ...Object.assign(form, newField) });
  };

  const addProduct = (id, name, count, description) => {
    const newProduct = { id, name, count, description };
    updateForm({
      ...form,
      products: [...form.products, newProduct],
    });
  };

  const removeProduct = (name) => {
    updateForm({
      ...form,
      products: form.products.filter((product) => product.name !== name),
    });
  };

  const setProductCount = (name, newCount) => {
    updateForm({
      ...form,
      products: form.products.map((product) =>
        product.name === name
          ? {
              ...product,
              count: newCount,
            }
          : product,
      ),
    });
  };

  const resetForm = () => {
    updateForm(initialState);
  };

  const resetOrder = () => {
    updateForm({
      ...form,
      ...R.pick(['deliveryDate', 'comment', 'products', 'extraEmailRecipients'], initialState),
    });
  };

  // Converts order fields to match the API
  const formatOrder = () => {
    const selectedAddress = form.useManualPickup ? manualPickupAddress : form.address;
    const address = `${selectedAddress.name}\n${selectedAddress.address}\n${selectedAddress.postalCode} ${selectedAddress.postalTown}`;
    const location = form.useManualPickup
      ? districtsDetailed[form.manualPickupDistrict].address[1] ||
        districtsDetailed[form.manualPickupDistrict].address[0]
      : `${form.address.postalCode} ${form.address.postalTown}`;
    const token = localStorage.getItem('token');
    const credentials = jwtDecode(token);
    const email = credentials.email;
    const products = form.products.map((product) => {
      return {
        type: product.id,
        count: product.count,
      };
    });
    const comment = form.useManualPickup ? `${form.comment}\nHentes hos PIT` : form.comment;
    return {
      received: moment(),
      deadline: form.deliveryDate,
      name: form.name,
      phone: form.phone,
      email: email,
      address,
      location,
      comment,
      district: form.district,
      units: products,
      extraEmailRecipients: form.extraEmailRecipients,
    };
  };

  const submitForm = async () => {
    const formattedOrder = formatOrder();
    const res = await submitOrder(formattedOrder);
    return res._id;
  };

  return (
    <FormContext.Provider
      value={{
        form,
        addProduct,
        removeProduct,
        setProductCount,
        dispatch: onChangeForm,
        resetForm,
        resetOrder,
        submitForm,
      }}>
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
