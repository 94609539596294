import API from './api';

/**
 * Sends a GET request to the 'eynventory_ping' endpoint.
 *
 * This function is the default export of the module. It makes a GET request
 * to the 'eynventory_ping' endpoint using the API module's GET method.
 * This can be used to check the status or presence of an inventory service.
 *
 * @returns {Promise<Object>} A promise that resolves to the response from the API.
 */
export default () => {
  return API.GET('eynventory_ping');
};
