import manualPickupAddress from './manualPickupAddress';

/**
 * Formats an address string based on the provided form data.
 *
 * This function takes form data and constructs a formatted address string. If the user
 * has opted for manual pickup (`useManualPickup` is true), it uses a predefined manual
 * pickup address. Otherwise, it uses the address provided in the form. The formatted
 * address includes the recipient's name, phone number, address details, and optionally
 * a district information if manual pickup is selected.
 *
 * @param {Object} form - The form data containing address and user details.
 * @returns {string} The formatted address string.
 */
const formatAddress = (form) => {
  const address = form.useManualPickup ? manualPickupAddress : form.address;
  return `${address.name}
Att: ${form.name}
Tlf: ${form.phone}
${address.address}
${address.postalCode} ${address.postalTown}${form.useManualPickup ? `\nDistrikt ${form.manualPickupDistrict}` : ``}`;
};

export default formatAddress;
