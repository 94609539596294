import React, { useContext } from 'react';
import LoadingContext from '../context/LoadingContext';
import { Backdrop, CircularProgress } from '@mui/material';

/**
 * LoadingPage component displays a loading indicator overlay when the application is in a loading state.
 * It uses the LoadingContext to determine whether to show the loading indicator.
 * The component features a Backdrop with a CircularProgress spinner, providing a visual cue that processing is ongoing.
 *
 * @returns {React.ReactElement} The LoadingPage component.
 */
const LoadingPage = () => {
  const { isLoading } = useContext(LoadingContext);

  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
      <CircularProgress color='primary' size={72} />
    </Backdrop>
  );
};

export default LoadingPage;
