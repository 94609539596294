import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import AuthContext from './context/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import NewOrder from './pages/NewOrder/NewOrder';
import Login from './pages/Login';
import FullscreenLoader from './pages/FullscreenLoader';
import LoadingContext from './context/LoadingContext';
import ping from './api/ping';

const App = () => {
  const { auth } = useContext(AuthContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [serverVersion, setServerVersion] = useState('');

  useEffect(() => {
    const fetchServerVersion = async () => {
      const response = await ping();
      setServerVersion(response.version);
    };
    fetchServerVersion();
  }, []);

  useEffect(() => {
    const tokenLogin = async () => {
      if (auth.token && !auth.isLoggedIn) {
        setIsLoading(true);
        await auth.tokenLogin(auth.token);
        setIsLoading(false);
      }
    };
    tokenLogin();
  }, []);

  if (!auth.isLoggedIn && isLoading) return <FullscreenLoader />;

  return (
    <div className='App'>
      <FullscreenLoader />
      <Header />
      {auth.isLoggedIn ? <NewOrder /> : <Login />}
      <Footer clientVersion={CLIENT_VERSION} serverVersion={serverVersion} />
    </div>
  );
};

export default App;
