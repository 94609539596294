import API from './api';

/**
 * Generates a session token for Google Places API.
 *
 * This function makes an asynchronous request to the API
 * to generate a new session token and stores it in the
 * local storage under the key 'googlePlacesToken'.
 *
 * @returns {Promise<void>} A promise that resolves when the token is stored.
 */
export const generateSessionToken = async () => {
  try {
    const res = await API.GET('address/sessionToken');
    localStorage.setItem('googlePlacesToken', res.token);
  } catch (err) {
    console.log(`Error: ${err.message}`);
  }
};

/**
 * Retrieves the detailed information of a specific address.
 *
 * This function fetches the detailed information of an address
 * by its ID. It uses the stored Google Places session token for
 * the request.
 *
 * @param {string} addressId - The unique identifier of the address.
 * @returns {Promise<Object>} A promise that resolves to the details of the address.
 */
export const getAddressDetails = (addressId) => {
  const sessionToken = localStorage.getItem('googlePlacesToken');
  const url = `address/details?id=${addressId}&sessionToken=${sessionToken}`;

  return API.GET(url);
};

/**
 * Autocompletes the address based on partial input.
 *
 * This function is the default export of the module. It sends
 * a request to the API to autocomplete a partially entered address.
 * It uses the stored session token for the API request.
 *
 * @param {string} address - The partial address to autocomplete.
 * @returns {Promise<Object>} A promise that resolves to the autocomplete results.
 */
export default async (address) => {
  const formattedAddress = encodeURIComponent(address);
  const sessionToken = localStorage.getItem('googlePlacesToken');
  const url = `address/autocomplete?address=${formattedAddress}&sessionToken=${sessionToken}`;

  return API.GET(url);
};
