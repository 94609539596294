import React from 'react';
import { Grid, Button } from '@mui/material';

/**
 * FormNavButtons component renders navigation buttons for a multi-page form.
 * It displays a 'Back' button if it's not the first page, and a 'Next' or 'Finish' button depending on the page status.
 *
 * @param {Object} props - The props of the component.
 * @param {number} props.page - The current page number of the form.
 * @param {boolean} props.isLast - Indicates whether the current page is the last one.
 * @param {Function} props.openPrevPage - Function to navigate to the previous page.
 * @param {boolean} props.canOpenNextPage - Indicates whether navigation to the next page is allowed.
 * @param {Function} props.openNextPage - Function to navigate to the next page.
 * @returns {React.ReactElement} The FormNavButtons component.
 */
export const FormNavButtons = ({ page, isLast, openPrevPage, canOpenNextPage, openNextPage }) => (
  <Grid container marginY={2} justifyContent='center' alignSelf='center' spacing={4}>
    {!isLast && page !== 0 && (
      <Grid item xs={6} sm={3} md={2} lg={1}>
        <Button fullWidth onClick={openPrevPage} variant='outlined'>
          Tilbake
        </Button>
      </Grid>
    )}
    <Grid item xs={6} sm={3} md={2} lg={1}>
      <Button fullWidth disabled={!canOpenNextPage} onClick={openNextPage} variant='contained'>
        {isLast ? 'Fullfør' : 'Neste'}
      </Button>
    </Grid>
  </Grid>
);
