import React, { useState, useEffect, useContext } from 'react';
import autocompleteAddress, { getAddressDetails } from '../api/addressSearch';
import AddressSelect from './AddressSelect';
import FormContext from '../context/FormContext';
import { Stack, TextField } from '@mui/material';
import useDebounce from '../hooks/useDebounce';
import { validateAddressStreet, validatePostalCode, validatePostalTown } from '../common/validators';

/**
 * A React component for address input with autocomplete, postal code, and postal town fields.
 * It allows users to search for addresses and autofill fields based on the selected address.
 * The component also validates the address, postal code, and postal town inputs.
 *
 * @param {Object} props - The component props.
 * @param {string} props.name - The name of the field in the form context.
 * @param {string} props.placeholder - The placeholder text for the address input field.
 * @param {Object} props.forcedAddress - An address object to prefill the component, bypassing user input.
 * @returns {React.ReactElement} The rendered component.
 */
export default ({ name, placeholder, forcedAddress }) => {
  const [searchResults, setSearchResults] = useState([]);
  const { form, dispatch } = useContext(FormContext);
  const { address } = form;
  const [addressSearchTerm, setAddressSearchTerm] = useState(address.address);
  const debouncedAddressSearchTerm = useDebounce(addressSearchTerm, 200);

  const useForcedAddress = typeof forcedAddress === 'object';

  useEffect(() => {
    if (debouncedAddressSearchTerm != address.address) {
      const newAddress = { ...address, address: debouncedAddressSearchTerm };
      setAddress(newAddress);
      if (!newAddress.address) {
        removeSearchResults();
        return;
      }
      lookupAddress(debouncedAddressSearchTerm);
    }
  }, [debouncedAddressSearchTerm]);

  useEffect(() => {
    setAddressSearchTerm(address.address);
  }, [form]);

  const setAddress = (newAddress) => {
    dispatch({ [name]: newAddress });
  };

  const setPostalCode = (newPostalCode) => {
    setAddress({ ...address, postalCode: newPostalCode });
  };

  const setPostalTown = (newPostalTown) => {
    setAddress({ ...address, postalTown: newPostalTown });
  };

  const removeSearchResults = () => {
    setSearchResults([]);
  };

  const lookupAddress = async (address) => {
    autocompleteAddress(address)
      .then((res) => {
        setSearchResults(res);
      })
      .catch((res) => console.log(res));
  };

  const autofillAddressFields = async (addressId) => {
    getAddressDetails(addressId)
      .then((res) => {
        const newAddress = { ...address, ...res };
        if (res.address) {
          if (addressSearchTerm === address.address) {
            setAddressSearchTerm(newAddress.address);
          }
          setAddress(newAddress);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (ev) => {
    if (!ev || !ev.target) return;
    setAddressSearchTerm(ev.target.value);
  };

  const handleSelect = (selectedAddress) => {
    autofillAddressFields(selectedAddress.id);
    removeSearchResults();
  };

  const onFocusAddressSearch = () => {
    if (address.name && !address.address) {
      lookupAddress(address.name);
    }
  };

  return (
    <>
      <Stack>
        <TextField
          label='Leveringsadresse'
          value={(useForcedAddress && forcedAddress.address) || addressSearchTerm}
          onChange={handleChange}
          placeholder={placeholder}
          style={{ marginBottom: 0 }}
          onFocus={onFocusAddressSearch}
          required
          disabled={useForcedAddress}
          error={!useForcedAddress && !validateAddressStreet(addressSearchTerm)}
        />
        <AddressSelect addresses={searchResults} onSelectAddress={handleSelect} onClickOutside={removeSearchResults} />
      </Stack>
      <Stack direction='row'>
        <TextField
          label='Postnr'
          value={(forcedAddress && forcedAddress.postalCode) || address.postalCode}
          placeholder='Postnr'
          onChange={(ev) => setPostalCode(ev.target.value)}
          required
          disabled={useForcedAddress}
          error={!useForcedAddress && !validatePostalCode(address.postalCode)}
        />
        <TextField
          label='Poststed'
          value={(forcedAddress && forcedAddress.postalTown) || address.postalTown}
          placeholder='Poststed'
          onChange={(ev) => setPostalTown(ev.target.value)}
          required
          disabled={useForcedAddress}
          error={!useForcedAddress && !validatePostalTown(address.postalTown)}
          fullWidth
        />
      </Stack>
    </>
  );
};
