import API from './api';

/**
 * Submits an order to the API.
 *
 * This function sends an order object to the API's 'ova/orders/externalOrder'
 * endpoint using a POST request. It relies on the API module's POST method
 * for handling the request.
 *
 * @param {Object} order - The order data to be submitted.
 * @returns {Promise<Object>} A promise that resolves to the response from the API.
 */
export const submitOrder = (order) => API.POST('ova/orders/externalOrder', order);

/**
 * Retrieves user details based on their email.
 *
 * This function makes a GET request to the API to fetch user details.
 * It constructs the URL using the user's email address, which is URI-encoded
 * to ensure proper formatting for the API request.
 *
 * @param {string} email - The email address of the user whose details are to be retrieved.
 * @returns {Promise<Object>} A promise that resolves to the user's details.
 */
export const getUserDetails = (email) => API.GET(`ova/orders/userDetails/${encodeURIComponent(email)}`);
