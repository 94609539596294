import React, { useState, useContext, useEffect } from 'react';
import { Grid, Paper, Avatar, TextField, Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AuthContext from '../context/AuthContext';

const paperStyle = {
  padding: 20,
  maxWidth: 300,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1em',
};
const avatarStyle = { backgroundColor: '#1bbd7e' };

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { auth } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Login component provides a user interface for authentication.
   * It includes form fields for email and password, and a button to submit the login request.
   * The component uses AuthContext for managing authentication state and handling login functionality.
   * It also maintains local state for form fields and a loading indicator.
   *
   * @returns {React.ReactElement} The Login component.
   */
  const onLogin = async (ev) => {
    ev.preventDefault();
    setIsLoading(true);
    await auth.login({ username: email, password: password });
    setIsLoading(false);
  };

  const onChange = (ev) => {
    if (ev.target.name === 'email') setEmail(ev.target.value);
    else if (ev.target.name === 'password') setPassword(ev.target.value);
  };

  return (
    <Grid>
      <form onSubmit={onLogin}>
        <Paper elevation={10} style={paperStyle}>
          <Grid align='center'>
            <Avatar style={avatarStyle}>
              <LockOutlinedIcon />
            </Avatar>
            <h2>Logg inn</h2>
          </Grid>
          <TextField
            error={auth.loginErrorMessage}
            name='email'
            value={email.value}
            onChange={onChange}
            label='Email'
            placeholder='Skriv inn email'
            fullWidth
            required
          />
          <TextField
            error={auth.loginErrorMessage}
            helperText={auth.loginErrorMessage}
            name='password'
            value={password}
            onChange={onChange}
            label='Passord'
            placeholder='Skriv inn passord'
            type='password'
            fullWidth
            required
          />
          {isLoading ? (
            <Button type='submit' color='primary' variant='contained' fullWidth disabled>
              Vennligst vent...
            </Button>
          ) : (
            <Button type='submit' color='primary' variant='contained' fullWidth>
              Logg inn
            </Button>
          )}
        </Paper>
      </form>
    </Grid>
  );
};

export default Login;
