import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Paper, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

/**
 * A React component for displaying a list of address suggestions and handling user interaction.
 * It allows users to navigate through the list using keyboard arrows and select an address by clicking or pressing Enter.
 * The component also handles click events outside the list.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.addresses - An array of address objects to be displayed as suggestions.
 * @param {Function} props.onSelectAddress - A callback function that is called when an address is selected.
 * @param {Function} props.onClickOutside - A callback function that is called when a click occurs outside the component.
 * @returns {React.ReactElement} The rendered list of address suggestions or an empty div if there are no addresses.
 */
const AddressSelect = ({ addresses, onSelectAddress, onClickOutside }) => {
  const [listIndex, setListIndex] = useState(0);
  const listRef = useRef(null);

  // If we type something which changes the search results, set the marker back to the top of the list
  useEffect(() => {
    setListIndex(0);
  }, [addresses]);

  const onKeyDown = useCallback(
    (event) => {
      if (!addresses || addresses.length === 0) return;
      if (event.keyCode === 40) {
        // arrown down
        // The Google API never returns more than 5 results
        setListIndex((current) => Math.min(4, ++current));
      } else if (event.keyCode === 38) {
        // arrow up
        setListIndex((current) => Math.max(0, --current));
      } else if (event.keyCode === 13) {
        // enter
        onSelectAddress(addresses[listIndex]);
      }
    },
    [addresses, listIndex, setListIndex, onSelectAddress],
  );

  const onMouseDown = useCallback(
    (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        onClickOutside();
      }
    },
    [listRef, onClickOutside],
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('mousedown', onMouseDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('mousedown', onMouseDown);
    };
  }, [addresses, listIndex, listRef, onKeyDown, onMouseDown]);

  if (!addresses || addresses.length === 0) {
    return <div></div>;
  }

  return (
    <Paper>
      <List ref={listRef}>
        {addresses.map((address, i) => (
          <ListItem key={address.id} disablePadding>
            <ListItemButton selected={listIndex === i} onClick={() => onSelectAddress(address)}>
              <ListItemText>{address.name}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default AddressSelect;
