import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import FormContext from '../context/FormContext';
import logo from '../images/eyasys-logo.svg';
import { Button } from '@mui/material';

/**
 * Logo component displays the company logo with different styles based on the application environment.
 * In production, it shows only the logo. In test and development environments, it additionally displays environment labels.
 *
 * @returns {React.ReactElement} The Logo component.
 */
const Logo = () => {
  const imageStyle = {
    height: '40px',
    padding: '15px 0 0 15px',
  };

  const testStyle = {
    backgroundColor: 'yellow',
    display: 'flex',
    justifyContent: 'flex-end',
  };

  const devStyle = {
    backgroundColor: 'lightGreen',
    display: 'flex',
    justifyContent: 'flex-end',
  };

  const LogoImg = <img src={logo} alt='Eyasys Logo' style={imageStyle} />;

  if (process.env.APP_ENV === 'prod' && process.env.NODE_ENV === 'production') {
    return <>{LogoImg}</>;
  } else if (process.env.APP_ENV === 'test') {
    return (
      <div style={testStyle}>
        {LogoImg}
        <p>TEST</p>
      </div>
    );
  } else {
    return (
      <div style={devStyle}>
        {LogoImg}
        <p>DEV</p>
      </div>
    );
  }
};

/**
 * Header component displays the application's header.
 * It shows the logo and, if the user is logged in, the user's email and a logout button.
 *
 * @returns {React.ReactElement} The Header component.
 */
const Header = () => {
  const { auth } = useContext(AuthContext);
  const { resetForm } = useContext(FormContext);

  const wrapperStyle = {
    display: 'flex',
    // width: '100%',
    justifyContent: 'flex-end',
  };

  const headerStyle = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  };

  const logout = () => {
    resetForm();
    auth.logout();
  };

  if (auth.isLoggedIn) {
    return (
      <div style={headerStyle}>
        <Logo />
        <div style={wrapperStyle}>
          <p>{auth.email}</p>
          <Button onClick={logout} variant='outlined' color='primary' style={{ margin: '1em' }}>
            Logg ut
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div style={headerStyle}>
      <Logo />
    </div>
  );
};

export default Header;
